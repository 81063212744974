// ------------------------------------------------------ ARTICLES //
// --------------------------- Force Download //
const forceDownload = (href, fileName) => {
	const link = document.createElement('a');

	link.setAttribute('href', href);
	link.setAttribute('download', fileName);
	link.setAttribute('target', '_blank');
	link.style.display = 'none';

	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};

// --------------------------- Article //
$('body').on('click', '#resources-slide .article, #resources-slide .featured-article, .side-articles .side-article', function(e) {
	e.preventDefault();
	const id = $(this).data('id');
	const type = $(this).data('type');
	const slug = $(this).data('slug');
	const url = `/${type}/${slug}`;
	const fileName = $(this).data('file');

	if (slug === 'no-link') {
		return false;
	}
	else if (type === 'white-papers' && fileName) {
		const href = `${s3}/${id}/${fileName}`;
		forceDownload(href, fileName);

		return false;
	}

	if ($(this).hasClass('side-article')) {
		window.location.href = url;
		return false;
	}

	window.open(url);
	return false;
});
